<template>
  <div v-html="codeData"></div>
  <!-- <div id="page-self-define-nav" style="width: 100vw; position: relative; left: -24px">
    <div
      class="title"
      style="
        height: 56px;
        line-height: 56px;
        font-size: 12px;
        align-items: center;
        display: flex;
        justify-content: center;
      "
    >
      <span style="margin-right: 2px">男子装备</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.76413 5.76432C3.89431 5.63414 4.10536 5.63414 4.23554 5.76432L7.99984 9.52862L11.7641 5.76432C11.8943 5.63414 12.1054 5.63414 12.2355 5.76432C12.3657 5.89449 12.3657 6.10555 12.2355 6.23572L8.24025 10.231C8.10749 10.3638 7.89221 10.3638 7.75942 10.231L3.76413 6.23572C3.63396 6.10555 3.63396 5.89449 3.76413 5.76432Z"
          fill="#14141E"
        />
      </svg>
    </div>
    <section
      id="page-self-define-sub-nav"
      style="display: none; height: 100vh; width: 100%; border-top: 1px solid #d0d0d0"
    >
      <ul style="line-height: 34px; padding: 24px">
        <li>
          <a href="/">新品推荐</a>
        </li>
        <li>
          <a href="/">上装</a>
        </li>
        <li>
          <a href="/">下装</a>
        </li>
        <li>
          <a href="/">鞋类</a>
        </li>
        <li>
          <a href="/">配件</a>
        </li>
      </ul>

      <script type="text/javascript">  
         function navClick () {
           var nav = document.getElementById('page-self-define-nav');
            nav.onclick = () => {
              const subNav = document.getElementById('page-self-define-sub-nav');
              const isShow = subNav.style.display === 'block';
              subNav.style.display = isShow ? 'none' : 'block';
            };
         }
         navClick();
      </script>
    </section>
  </div> -->
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      extractedScripts: []
    };
  },
  computed: {
    codeData() {
      const code = this.data.content.code || {};

      return code;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scriptRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/i;
      const match = this.codeData.match(scriptRegex);
      const scriptContent = match ? match[1].trim() : null;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = scriptContent;
      document.body.appendChild(script);
    });
  }
};
</script>
