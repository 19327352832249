<!--
 * @Author: xiejun jun.xie@gtechhdigi.com
 * @Date: 2024-07-19 16:58:14
 * @LastEditors: xiejun jun.xie@gtechhdigi.com
 * @LastEditTime: 2024-08-30 18:19:48
 * @FilePath: /ua-cnbs-mall/src/components/LandingPage/plp.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <PLP
    :preview-data="plp"
    :params="sendParams"
    :hidden-filter="!isDisplayFilter"
    :is-display-sort="isDisplaySort"
    :adv-img-url="advImgUrl"
    :wcm-columns="columns"
    :ad-occupies-columns="advImgCoverColumn || 2"
    :title="pageData.content && pageData.content.productListName"
    class="condition-product-list"
  />
</template>

<script>
import { generateFilterArr } from '@speedshop/template';
import PLP from '@/components/PLP/plp.vue';
import { CatalogProduct, CatalogWcmProduct } from '@/utils/product';
export default {
  components: {
    PLP
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      plp: null
    };
  },
  computed: {
    sendParams() {
      return {
        ...this.generateFilter(),
        prefetch: true,
        pageSize: 10,
        pageNum: 1
      };
    },
    isDisplayFilter() {
      return this.pageData?.content?.isDisplayFilter;
    },
    isDisplaySort() {
      return this.pageData?.content?.isDisplaySort;
    },
    isDisplayAdvImg() {
      return this.pageData?.content?.isDisplayAdvImg;
    },
    advImgUrl() {
      return this.pageData?.content?.advImgUrl;
    },
    columns() {
      return this.pageData?.content?.columns;
    },
    advImgCoverColumn() {
      return this.pageData?.content?.advImgCoverColumn;
    }
  },
  async created() {
    const data = this.generateFilter();
    await this.getPlp(data);
  },
  methods: {
    // WCM配置的过滤数据
    generateFilter() {
      if (this?.pageData?.content?.type || this?.pageData?.type) {
        this.type = this?.pageData?.content?.type || this?.pageData?.type;
        if (this.type === '01' || this.type === 'productWithCondition') {
          const content = this?.pageData?.content || {};
          const params = content.conditions || content.condition || {};
          const topProducts = content.topProducts || [];
          const condition = generateFilterArr(params, topProducts);
          return condition;
        } else {
          return null;
        }
      }
    },
    async getPlp(data) {
      this.rerender = false;
      const Plp = ['home', 'LandingPage', 'LandingPage-all'].includes(this.$route.name)
        ? CatalogWcmProduct()
        : CatalogProduct();
      await Plp.init({ ...data });
      this.plp = Plp;
    }
  }
};
</script>
