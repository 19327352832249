export default {
  methods: {
    lazyLoadImg() {
      const images = document.querySelectorAll('img[data-src]');
      images.forEach(image => {
        if (this.isInViewport(image)) {
          image.src = image.getAttribute('data-src');
          image.removeAttribute('data-src');
        }
      });
    },
    isInViewport(element, distance = 200) {
      const rect = element.getBoundingClientRect();

      return (
        rect.bottom > 0 &&
        rect.top < (window.innerHeight || document.documentElement?.clientHeight) + distance
      );
    }
  }
};
