<template>
  <gtLandingPage
    ref="lp"
    :priority-wcm-column="true"
    :list="newDataList"
    :iframe-style-config="iframeStyleConfig"
    :plp-default-column="3"
    :data-list-length="newDataList.length"
    :data-landing-level="landingLevel"
    class="sp-landing-page"
  >
    <PLP
      slot="conditionProductList"
      slot-scope="{ data, operationData }"
      :page-data="data"
      :operation-data="operationData"
    ></PLP>
    <ProductCode
      slot="codeProduct"
      slot-scope="{ data, layout, layoutId }"
      :data="data"
      :layout="layout"
      :layout-id="layoutId"
    />
    <template slot="carousel" slot-scope="{ data }">
      <!-- 穿搭推荐 -->
      <SwiperRecommend
        v-if="data.layoutId === 'widget-swiper-with-recommend-product'"
        :data="data"
      ></SwiperRecommend>
      <!-- 优惠券 -->
      <SwiperCoupon
        v-else-if="
          ['widget-coupon-swiper', 'widget-coupon-template-1'].includes(
            data.content.widgetItem && data.content.widgetItem.widgetCode
          )
        "
        :data="data"
      ></SwiperCoupon>
      <!-- 尖货 -->
      <SwiperHot
        v-else-if="
          data.content.widgetItem && data.content.widgetItem.widgetCode === 'widget-hot-product'
        "
        :data="data"
      ></SwiperHot>
      <Swiper
        v-else-if="
          ['widget-collection-selection', 'widget-each-one-is-hard'].includes(data.layoutId)
        "
        ref="menulSwiperRef"
        class="each-one-is-hard__side-swiper"
        :data="data"
        :no-autoplay="true"
        :no-pagination="true"
        :no-swiping="true"
        :no-navigation="true"
      ></Swiper>
      <Swiper v-else :data="data"></Swiper>
    </template>
    <LPButton slot="button" slot-scope="{ data }" :data="data"></LPButton>
    <Code slot="code" slot-scope="{ data }" :data="data"></Code>
    <!-- <Other slot="other" slot-scope="{ data }" :data="data"></Other> -->
    <Promotion slot="promotion" slot-scope="{ data }" :page-data="data"></Promotion>
    <Text1 slot="text" slot-scope="{ data }" :data="data"></Text1>
    <Image1 slot="image" slot-scope="{ data }" :data="data"></Image1>
    <Tab slot="tab" slot-scope="{ data }" :data="data" style="width: 100%" @tabSwitch="onTabSwitch">
      <template slot-scope="{ subData, index }">
        <LandingPage :key="index" :column-list="subData.list"></LandingPage>
      </template>
    </Tab>
    <!-- <Tabs
      slot="tab"
      slot-scope="{ data }"
      :value="data.couselList && data.couselList[0].url"
      :stretch="false"
      class="gt-tabs"
      style="width: 100%"
    >
    </Tabs> -->
    <template slot="other" slot-scope="{ data }">
      <TabAnchor v-if="data.type === 'anchorPoint'" :data="data">
        <template slot-scope="{ subData, index }">
          <LandingPage :key="index" :column-list="subData.list"></LandingPage>
        </template>
      </TabAnchor>
      <Other v-else :data="data"></Other>
    </template>
  </gtLandingPage>
</template>

<script>
import { gtLandingPage, componentsCommon, GtSwiper } from '@speedshop/template';
// import { Tabs, TabPane } from 'element-ui';
import LPButton from './button.vue';

// import ProductSwiper from '@/components/LandingPage/productListSwiper.vue';
import ProductCode from '@/components/LandingPage/codeProduct.vue';
import Swiper from '@/components/LandingPage/swiper.vue';
import PLP from '@/components/LandingPage/plp.vue';
import Code from '@/components/LandingPage/code.vue';
import Other from '@/components/LandingPage/other.vue';
import Promotion from '@/components/LandingPage/promotion.vue';
import Tab from '@/components/LandingPage/tab.vue';
import Text1 from '@/components/LandingPage/text.vue';
import Image1 from '@/components/LandingPage/img.vue';
import SwiperRecommend from '@/components/LandingPage/swiper-recommend-product.vue';
import SwiperHot from '@/components/LandingPage/swiper-hot.vue';
import SwiperCoupon from '@/components/LandingPage/swiper-coupon.vue';
// import { scrollToTopW } from '@/utils/dom';
import LandingPage from '@/components/LandingPage/index.vue';
import TabAnchor from '@/components/LandingPage/tab-anchor.vue';

export default {
  name: 'LandingPage',
  components: {
    gtLandingPage,
    // ProductSwiper,
    PLP,
    ProductCode,
    Swiper,
    Code,
    Other,
    Promotion,
    Tab,
    Text1,
    Image1,
    // Tabs,
    // TabPane,
    SwiperRecommend,
    SwiperHot,
    SwiperCoupon,
    LPButton,
    LandingPage,
    TabAnchor
    // GtSwiper
  },
  mixins: [componentsCommon],
  inject: {
    landingLevel: { default: 1 }
  },
  provide() {
    return {
      landingLevel: this.landingLevel + 1
    };
  },
  props: {
    columnList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      seoInfo: {},
      iframeStyleConfig: `<style>
      *{
        font-family:Neue Plak, PingFang SC, "微软雅黑", "Noto Sans CJK SC", sans-serif !important
      }
      </style> `,
      waterFallOptions: {
        gutter: 16
      },
      observer: null,
      stickyTop: this.isMobile ? 94 : 100,
      choiceIndex: 0
    };
  },
  computed: {
    newDataList() {
      const setLayoutId = columnList => {
        const data = columnList
          .map(item => {
            const layoutId =
              item.style && item.style.widgetItem && item.style.widgetItem.widgetCode;
            const { blockList = [], columns = [] } = item;
            return {
              ...item,
              layoutId,
              columns: columns.map(i => {
                const { blockList } = i;
                return {
                  ...i,
                  layoutId,
                  blockList: blockList.map(c => {
                    return {
                      ...c,
                      layoutId,
                      columns: c.columns.map(e => {
                        if (e.type === 'tab' && e.couselList) {
                          console.log(e.couselList, 'tab');
                          return {
                            ...e,
                            layoutId,
                            couselList: (e.couselList || []).map(c => {
                              return {
                                ...c,
                                list: setLayoutId(c.list)
                              };
                            })
                          };
                        } else {
                          return { ...e, layoutId };
                        }
                      })
                    };
                  })
                };
              }),
              blockList: blockList.map(i => {
                return {
                  ...i,
                  layoutId
                };
              })
            };
          })
          .filter(item => {
            return !(
              item.style?.widgetItem?.widgetCode === 'widget-without-login' &&
              this.isLogin &&
              !this.isGuest
            );
          });
        return data;
      };
      const res = setLayoutId(this.columnList);
      const filterData = res.filter(item => item.type === 'layout' && item?.style?.status !== 0);
      return filterData;
    }
  },

  watch: {},
  mounted() {
    this.setExposeType();
    this.initAnimation();
    this.$nextTick(() => {
      this.loading = false;
    });
    setTimeout(() => {
      this.observerScroll();
    }, 1000);
    this.lazyLoad();
  },
  destroyed() {
    this.observer && this.observer.disconnect();
    document.removeEventListener('scroll', this.listenerScroll);
  },
  methods: {
    onTabSwitch(index, tabEl) {
      // this.choiceIndex = index;
      // this.$refs.menulSwiperRef?.setActiveIndex(index);
      const wrapper =
        tabEl?.closest?.('.widget-widget-each-one-is-hard >.row-box>.sp-row-wrapper') ||
        tabEl?.closest?.('.widget-widget-collection-selection >.row-box>.sp-row-wrapper');
      const target = wrapper?.previousElementSibling?.querySelector?.(
        '.each-one-is-hard__side-swiper'
      );
      // console.log('onTabSwitch', tabEl, wrapper, target);
      target?.__vue__?.setActiveIndex?.(index);
      // console.log('onTabSwitch', index);
      setTimeout(() => {
        this.lazyLoad();
      }, 500);
    },
    // handleHardClick(index) {
    //   this.choiceIndex = index;
    // },
    setExposeType() {
      const doms = this.$refs.lp?.$el.querySelectorAll('[module_name]');
      [...doms].forEach(item => {
        const isInView = this.isInViewport(item);
        isInView
          ? item.setAttribute('expose_type', '自动曝光')
          : item.setAttribute('expose_type', '手动滑动');
      });
    },
    initAnimation() {
      const container = this.$refs.lp;
      if (!container) return;
      const layouts = container?.$el.children || [];

      // 创建Intersection Observer实例
      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry, index) => {
          // 当元素进入可视区域时添加动画类，否则移除动画类
          if (entry.intersectionRatio <= 0) return;

          // 过渡动画

          //  entry.target.classList.add('page-block-show');
          // 神策曝光
          const operation = entry.target.children || [];
          // const operationList = (operation.length && operation[0].children) || [];
          const trackData = {};
          // eslint-disable-next-line camelcase

          [...operation].forEach(target => {
            const attributes = target.attributes;
            for (let i = 0; i < attributes.length; i++) {
              trackData[attributes[i].name] = attributes[i].value;
            }
            this.$trackHelp.track('operationExpose', { ...trackData });
          });
          observer.unobserve(entry.target);
        });
      });
      this.observer = observer;

      [...layouts].forEach((element, index) => {
        const target = element.lastChild;
        observer.observe(target);
      });
    },
    observerScroll() {
      document.addEventListener('scroll', this.listenerScroll);
    },
    listenerScroll() {
      const ElList = document.querySelectorAll(
        '.widget-widget-tab-sticky-to-top .gt-tabs.is-sticky, .sp-landing-tab-anchor .gt-tabs.is-sticky'
      );
      [...ElList].forEach($el => {
        // 兼容华为微信浏览器，getBoundingClientRect().top => 93.99884033203125
        if (
          parseFloat($el.getBoundingClientRect().top.toFixed(4)) <=
          parseFloat(window.getComputedStyle($el).top)
        ) {
          $el.classList.add('home-category-lp-shadow');
        } else {
          $el.classList.remove('home-category-lp-shadow');
        }
      });
    },
    // handleClick(item, content = { widgetItem: {} }, index) {
    //   const { widgetItem: { widgetCode } = {} } = content;
    //   this.choiceIndex = index;
    //   console.log(content, 'content', index);
    //   console.log(widgetCode, 'widgetCode');
    //   if (widgetCode === 'widget-tab-sticky-to-top') {
    //     const tab = item.target;
    //     const tabHeader = tab.parentElement.parentElement.parentElement.parentElement.parentElement;

    //     const boundingData = tabHeader.getBoundingClientRect();
    //     console.log(tab, 'tab');

    //     console.log(tabHeader, 'tabHeader');
    //     const header = this.isMobile
    //       ? document.querySelector('.mobile_header-layer')
    //       : document.querySelector('.gt-header ');
    //     this.$nextTick(() => {
    //       console.log(1);
    //       const $container = tabHeader.parentElement;
    //       const noAd = document.querySelector('.gt-layout-without-ad');
    //       console.log(2);
    //       const stickyTop = this.isMobile && noAd ? 54 : this.isMobile ? 94 : 99;
    //       console.log(3);
    //       if (boundingData.top > stickyTop) {
    //         console.log(4, boundingData.top, stickyTop);
    //         this.$nextTick(() => {
    //           this.lazyLoad();
    //         });

    //         return;
    //       }
    //       console.log(5);
    //       console.log(window.scrollY, '$container.offsetTop');
    //       console.log($container.getBoundingClientRect().top, 'tabHeader.clientHeight');
    //       console.log(header.clientHeight, 'header.clientHeight');
    //       const headerMarginBottom = 20;
    //       const Top =
    //         window.scrollY +
    //         $container.getBoundingClientRect().top -
    //         stickyTop +
    //         headerMarginBottom;

    //       scrollToTopW(Top);
    //     });
    //   } else {
    //     this.$nextTick(() => {
    //       this.lazyLoad();
    //     });
    //   }
    // },
    lazyLoad() {
      const images = document.querySelectorAll('img[data-src]');
      images.forEach(image => {
        if (this.isInViewport(image)) {
          image.src = image.getAttribute('data-src');
          image.removeAttribute('data-src');
        }
      });
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();

      return (
        rect.bottom > 0 &&
        rect.top < (window.innerHeight || document.documentElement?.clientHeight) + 200
      );
    }
  }
};
</script>

<style scoped>
.gt-plp-page {
  width: 100%;
}
</style>
<style lang="scss">
.sp-plp-card__wrapper .sp-plp-card__img .el-image {
  height: auto !important;
}
.widget-widget-img-tab {
  .el-tabs__header.is-top {
    .el-tabs__nav {
      background: #14141e;
    }
    .el-tabs__nav-scroll {
      width: 100%;
      min-width: 100%;
    }
  }
  .sp-landing-tab-anchor > .el-tabs {
    .el-tabs__item {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}

.widget-widget-img-tab,
.widget-widget-img-tab-not-fill {
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__header.is-top {
    margin: 0px;
  }
  .el-tabs__nav.is-top {
    display: flex;
    .el-tabs__item {
      padding: 0px !important;
      height: auto !important;
    }
  }

  .tab-img-box {
    .default-img,
    .selected-img {
      width: 100%;
    }
    .default-img {
      display: block;
    }
    .selected-img {
      display: none;
    }
  }
  .is-active {
    .default-img {
      display: none;
    }
    .selected-img {
      display: block;
    }
  }
  .sp-landing-tab-anchor > .el-tabs {
    margin: 0;
    padding: 0;
    .el-tabs__header.is-top {
      display: block !important;
      justify-content: unset !important;
      margin: 0px;
    }
    &.home-category-lp-shadow {
      padding: 8px 0;
    }
  }
}
.widget-widget-img-tab-not-fill {
  .el-tabs__nav.is-top {
    .el-tabs__item {
      width: 160px;
      max-width: 160px;
    }
  }
  .sp-landing-tab-anchor {
    .el-tabs__nav-wrap {
      display: block !important;
      justify-content: unset !important;
    }
  }
}
.sp-landing-tab-content {
  width: 100%;
}
// .sp-landing-tab-content .sp-landing-page.sp-landingpage-collection > .sp-row {
//   margin: unset;
//   padding: unset;
// }
</style>
<style lang="scss">
.page-layout_mobile {
  .sp-landingpage-collection .sp-row .sp-row-wrapper {
    z-index: auto !important;
  }
}
.sp-landing-promotionblock {
  .sp-plp-page .sp-plp-top {
    position: relative;
    top: 0px;
  }
}
.LandingPage,
.LandingPage-all {
  .page-layout_pc {
    .sp-landing-page {
      .sp-row,
      .el-tabs__content {
        overflow: inherit;
      }
    }
  }
}
</style>
