<template>
  <section ref="container" class="sp-landing-carouselblock carousel-block">
    <GtSwiper
      ref="swiper"
      :data="choiceIndex >= 0 ? newSwiperDataList : data.content.list || []"
      :swiper-option="swiperOption"
    >
      <div
        slot="default"
        slot-scope="{ data: subData, index }"
        class="lp-swiper-detail"
        :class="subData.widgetItem && subData.widgetItem.widgetCode"
      >
        <p v-if="subData.captionPosition === 'top'" class="caption">
          {{ subData.caption }}
        </p>
        <p v-if="subData.captionPosition === 'cover'" class="cover-title caption">
          {{ subData.caption }}
        </p>
        <GtLink
          :to="subData.link"
          style="width: 100%"
          :module_name="data.operationData.module_name"
          :module_rank="data.operationData.module_rank"
          :operation_name="data.operationData.operation_name"
          :operation_id="data.operationData.operation_id"
          :operation_rank="data.operationData.operation_rank"
          :destination_url="subData.link"
          click_type="图片"
          expose_type="手动滑动"
          class="operation-img-link"
          target="_blank"
          :click_content="subData.altText"
        >
          <img
            v-if="data.layoutId === 'layout-first-module'"
            :src="subData.url + '?x-oss-process=image/format,webp'"
            :alt="subData.altText || 'UA banner 图'"
          />

          <img
            v-else
            :data-src="subData.url + '?x-oss-process=image/format,webp'"
            :alt="subData.altText || 'UA banner 图'"
            class="swiper-lazy-img"
            src="@/assets/image/default-img.png"
            style="width: 100%"
          />
          <div v-if="subData.fixedImg" class="fixed-container">
            <img
              v-if="data.layoutId === 'layout-first-module'"
              :src="subData.fixedImg + '?x-oss-process=image/format,webp'"
              :alt="subData.altText || 'UA banner 图'"
            />
            <img
              v-else
              :data-src="subData.fixedImg + '?x-oss-process=image/format,webp'"
              :alt="subData.altText || 'UA banner 图'"
              class="swiper-lazy-img"
              src="@/assets/image/default-img.png"
            />
            <div v-if="subData.textList && subData.textList.length" class="fixed-container_text">
              <p v-for="(item, index) in subData.textList" :key="index">{{ item.textDesc }}</p>
            </div>
          </div>
          <div v-else>
            <div v-if="subData.textList && subData.textList.length" class="fixed-container_text">
              <p
                v-for="(item, index) in subData.textList"
                :key="index"
                :style="{
                  top: `${item.percentageTop}%`,
                  left: `${item.percentageLeft}%`,
                  fontSize: `${item.fontSize}px`
                }"
                v-html="item.textDesc"
              ></p>
            </div>
          </div>
        </GtLink>
        <p v-if="subData.captionPosition === 'bottom'" class="caption">
          {{ subData.caption }}
        </p>
        <div v-if="subData.buttonList && subData.buttonList.length">
          <GtLink
            v-for="(btn, i) in subData.buttonList"
            :key="`${index}-${i}`"
            :to="btn.link"
            class="sp-lp-img_btn_link operation-img-link"
            :style="{
              top: `${btn.percentageTop}%`,
              left: `${btn.percentageLeft}%`,
              width: `${btn.btnPercentageWidth}%`
              // height: `${btn.height}px`,
              // lineHeight: `${btn.height}px`
            }"
            :module_name="data.operationData.module_name"
            :module_rank="data.operationData.module_rank"
            :operation_name="data.operationData.operation_name"
            :operation_id="data.operationData.operation_id"
            :operation_rank="data.operationData.operation_rank"
            :destination_url="btn.link"
            click_type="按钮"
            expose_type="手动滑动"
            target="_blank"
            :click_content="btn.text"
          >
            <GtButton class="sp-lp-img_btn">
              {{ btn.text }}
            </GtButton>
          </GtLink>
        </div>
      </div>
      <template slot="pagination">
        <div>
          <div v-if="data.content.twoSidesButton">
            <div ref="nextLeft" class="swiperj-button-next swiper-default-next">
              <svg
                v-if="
                  data.content.widgetItem &&
                  ['widget-swiper-black-pagination', 'widget-main-kv-bg-black'].includes(
                    data.content.widgetItem.widgetCode
                  )
                "
                width="48"
                height="65"
                viewBox="0 0 48 65"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="24-2px-arrow right">
                  <g id="outline (Stroke)" filter="url(#filter0_d_6205_12155)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.8787 11.7927C11.4882 12.1832 11.4882 12.8164 11.8787 13.2069L31.1716 32.4998L11.8787 51.7927C11.4882 52.1832 11.4882 52.8164 11.8787 53.2069L13.2929 54.6211C13.6834 55.0117 14.3166 55.0117 14.7071 54.6211L35.4142 33.914C36.1953 33.133 36.1953 31.8666 35.4142 31.0856L14.7071 10.3785C14.3166 9.98797 13.6834 9.98797 13.2929 10.3785L11.8787 11.7927Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_6205_12155"
                    x="1.58594"
                    y="0.0854492"
                    width="44.4141"
                    height="64.8286"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_6205_12155"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_6205_12155"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
              <i v-else class="iconfont icon-a-24-2px-arrowright"></i>
            </div>
            <div ref="prevLeft" class="swiperj-button-prev swiper-default-prev">
              <svg
                v-if="
                  data.content.widgetItem &&
                  ['widget-swiper-black-pagination', 'widget-main-kv-bg-black'].includes(
                    data.content.widgetItem.widgetCode
                  )
                "
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="24-2px-arrow left" clip-path="url(#clip0_6205_12156)">
                  <g id="outline (Stroke)" filter="url(#filter0_d_6205_12156)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.2932 3.7927C35.6837 4.18323 35.6837 4.8164 35.2932 5.20692L16.0003 24.4998L35.2932 43.7927C35.6837 44.1832 35.6837 44.8164 35.2932 45.2069L33.879 46.6211C33.4885 47.0117 32.8553 47.0117 32.4648 46.6211L11.7577 25.914C10.9766 25.133 10.9766 23.8666 11.7577 23.0856L32.4648 2.37849C32.8553 1.98797 33.4885 1.98797 33.879 2.37849L35.2932 3.7927Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_6205_12156"
                    x="1.17188"
                    y="-7.91455"
                    width="44.4141"
                    height="64.8286"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_6205_12156"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_6205_12156"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_6205_12156">
                    <rect width="48" height="48" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>

              <i v-else class="iconfont icon-a-24-2px-arrowleft"></i>
            </div>
          </div>
          <div v-if="data.content.bottomButton" class="swiper-pagination" />
        </div>
      </template>
    </GtSwiper>
  </section>
</template>
<script>
import { GtLink, GtButton, componentsCommon, GtSwiper } from '@speedshop/template';
// componentsCommon
export default {
  directives: {},
  components: {
    GtLink,
    GtButton,
    GtSwiper
  },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    },
    speed: {
      type: Number,
      default: 500
    },
    noSwiping: {
      type: Boolean,
      default: false
    },
    noPagination: {
      type: Boolean,
      default: false
    },
    noAutoplay: {
      type: Boolean,
      default: false
    },
    noNavigation: {
      type: Boolean,
      default: false
    },
    // 精选商品 点击tab会显示对应的轮播页面  本来是在外部处理好数据传进来的 但是有渲染问题 所以把点击的下标传进来处理
    choiceIndex: {
      type: Number
    }
  },
  data() {
    return {
      rendered: false,
      newList: [],
      productSwiperOption: {
        direction: 'vertical',
        slidesPerView: 'auto',
        slidesPerGroup: 2
      }
    };
  },

  computed: {
    swiperOption() {
      const {
        layoutId,
        content: {
          autoPlay = false,
          Qty = 1,
          timer = 3000,
          bottomButton = true,
          twoSidesButton = true,
          speed = this.speed,
          widgetItem: { widgetCode = layoutId } = {}
        }
      } = this.data || {};
      const slideNumMap = {
        'cnbs-carousel__member-benefits': this.isMobile ? 1.6 : 5,
        'widget-swiper-slide-1-3': 1.3,
        'widget-swiper-with-recommend-product': this.isMobile ? 1.2 : 1,
        'widget-swiper-column-2-2': 2.4
      };
      const slideGapMap = {
        // 尖货
        'widget-hot-product': this.isMobile ? 12 : 24,
        // 关注我们
        'widget-home-follow-us': 12,
        // 明星产品
        'widget-star-product': this.isMobile ? 12 : 0,
        'widget-swiper-with-recommend-product': this.isMobile ? 12 : 48
      };
      const effectMap = {
        'widget-home-pro': 'fade',
        'widget-star-product': 'coverflow'
      };
      const loopMap = {
        'widget-star-product': true,
        'layout-first-module': true,
        'widget-swiper-black-pagination': true,
        'widget-main-kv-bg-black': true
      };
      const slidesOffsetAfterMap = {
        'widget-swiper-with-recommend-product': this.isMobile ? 0 : 0
      };
      const slidesPerView = slideNumMap[widgetCode] ? slideNumMap[widgetCode] : Qty;
      const spaceBetween = slideGapMap[widgetCode]
        ? slideGapMap[widgetCode]
        : this.isMobile && slidesPerView > 1
        ? 8
        : slidesPerView === 1
        ? 0
        : 16;
      return {
        effect: effectMap[widgetCode] || 'slide',
        noSwiping: this.noSwiping,
        updateOnWindowResize: true,
        speed,
        observer: true, // 开启动态检查器，监测swiper和slide
        observeParents: true, // 监测Swiper 的祖/父元素
        spaceBetween: slideGapMap[widgetCode]
          ? slideGapMap[widgetCode]
          : this.isMobile && slidesPerView > 1
          ? 8
          : slidesPerView === 1
          ? 0
          : 16,

        freeMode: false,
        autoplay:
          !this.noAutoplay && autoPlay
            ? {
                delay: timer,
                disableOnInteraction: true
              }
            : false,
        slidesPerView,
        loop: loopMap[widgetCode] || loopMap[layoutId],
        loopFillGroupWithBlank: loopMap[widgetCode],
        loopAdditionalSlides: 3,
        breakpoints: {
          1441: {
            spaceBetween: spaceBetween === 24 ? 48 : spaceBetween,
            slidesPerView
          }
        },
        pagination:
          !this.noPagination && bottomButton
            ? {
                el: '.swiper-pagination',
                clickable: true // 允许分页点击跳转
              }
            : false,
        // 设置点击箭头
        navigation:
          !this.noNavigation && twoSidesButton
            ? {
                nextEl: '.swiper-default-next',
                prevEl: '.swiper-default-prev'
              }
            : {},
        uniqueNavElements: true
      };
    },
    newSwiperDataList() {
      return [this.data.content.list[this.choiceIndex]];
    }
  },
  async created() {
    this.newList = await this.formatData();
  },
  mounted() {
    if (!this.noAutoplay) {
      this.$nextTick(() => {
        this.rendered = true;

        const {
          content: { autoPlay = false }
        } = this.data || {};
        setTimeout(() => {
          const mySwiper = this.$refs.swiper && this.$refs.swiper.swiper;
          if (!mySwiper) return;
          // 鼠标覆盖停止自动切换
          mySwiper.el.onmouseover = function () {
            mySwiper.autoplay && mySwiper.autoplay.stop();
          };

          // 鼠标离开开始自动切换
          mySwiper.el.onmouseout = function () {
            autoPlay && mySwiper.autoplay?.start();
          };
          mySwiper && mySwiper.update();
        }, 500);
      });
    }
  },
  methods: {
    setActiveIndex(index, duration = 500) {
      this.$refs.swiper.swiper.slideTo(index, duration, true);
    },
    formatData() {
      const list =
        (this.data && this.data.content && this.data.content.list && this.data.content.list) || [];

      const newDataList = list.map((item, index) => {
        const { backgroundImage, url, ...others } = item;

        const newItem = {
          ...others,
          url: backgroundImage || url,
          fixedImg: backgroundImage ? url : ''
        };

        return newItem;
      });
      return newDataList;
    },
    compare(property) {
      return function (a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    }
  }
};
</script>
<style lang="less">
.sp-landing-carouselblock {
  max-width: 100%;
  width: 100%;
  position: relative;

  .swiper-slide {
    width: 100%;
    height: auto;
    max-width: 100% !important;
    position: relative;

    .lp-swiper-detail {
      display: flex;
      align-items: center;

      .lp-product-list {
        min-width: 50%;
        flex-grow: 0;
        margin-left: 48px;
      }

      @media (max-width: 1440px) {
        .lp-product-list {
          margin-left: 24px;
        }
      }
    }

    .fixed-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    > div {
      > span {
        display: block;

        img {
          width: 100%;
          display: block;
        }
      }

      > a {
        display: block;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    .slide-content-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      a {
        width: 100%;
      }

      img {
        width: 100%;
        display: block;
      }

      .cover-title {
        position: absolute;
        top: 10px;
        text-align: center;
        z-index: 2;
      }
    }
  }

  .swiper-wrapper {
    width: 100%;

    .caption {
      padding: 25px 0px 31px 0px;
    }
  }

  .sp-swiper-container {
    overflow: hidden;

    .sp-lp-img_btn_link {
      position: absolute;

      .sp-lp-img_btn {
        width: 100% !important;
        height: 100% !important;
        line-height: 1.1 !important;
      }
    }

    .fixed-container_text {
      p {
        position: absolute;
        line-height: 1.2;
      }
    }

    .swiperj-button-next,
    .swiperj-button-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
      display: flex;
      justify-content: center;

      i {
        font-size: 32px;
      }
    }

    .swiper-button-disabled {
      color: #b4b4bf;
      display: none;
    }
  }
}

.page-layout_mobile {
  .sp-landing-page > .sp-row:not(.widget-widget-layout-width-fill) {
    .sp-swiper-container {
    }
  }

  .sp-landing-carouselblock.carousel-block {
    .swiper-pagination {
      bottom: 12px;
    }

    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      margin: 4px;

      &.swiper-pagination-bullet-active {
        width: 16px;
        height: 4px;
      }
    }
  }

  .swiperj-button-prev,
  .swiperj-button-next {
    font-size: 24px;
    color: #14141e;
    top: 40.5vw;

    &.swiper-button-disabled {
      color: #b4b4bf;
      display: none;
    }
  }

  .swiperj-button-prev {
    left: 20px;
  }

  .swiperj-button-next {
    right: 20px;
  }
}

.sp-landing-carouselblock.carousel-block {
  .swiper-pagination {
    bottom: 20px;
  }

  .swiper-pagination-bullet {
    background: var(--white-45, rgba(255, 255, 255, 0.45));
    width: 6px;
    height: 6px;
    margin: 6px;
    border-radius: 0px;
    opacity: 1;
    margin-bottom: 0px;

    &.swiper-pagination-bullet-active {
      width: 32px;
      height: 6px;
      background: var(--main-color-ua-white, #fff);
    }
  }
}
</style>
