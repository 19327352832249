<template>
  <div class="page-lp-promotion-plp">
    <!-- <GtFlashSale
      v-if="render"
      :show-count-down="showCountDown"
      :show-tip-text="false"
      :has-days="true"
      :has-top-txt="true"
      :has-border-bottom="false"
      :divider-background="true"
      :start-time="activityInfo.activityBegin"
      :end-time="activityInfo.activityEnd"
      :has-start-count-down="true"
      :time-has-background="true"
      :active-name="activityInfo.activityName"
    >
      <template #flash-sale-right />
    </GtFlashSale> -->

    <PLP
      :preview-data="plp"
      :params="sendParams"
      :hidden-filter="false"
      :is-display-sort="true"
      :wcm-columns="columns"
      :title="activityInfo.activityName"
    />
  </div>
</template>

<script>
import { GtFlashSale } from '@speedshop/template';
import { useLandingPage } from '@speedshop/sdk-api';
import PLP from '@/components/PLP/plp.vue';
import { CatalogProduct, CatalogWcmProduct } from '@/utils/product';
export default {
  components: {
    PLP,
    GtFlashSale
  },
  props: {
    pageData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      plp: null,
      activityInfo: {},
      render: false
    };
  },
  computed: {
    sendParams() {
      return {
        ...this.generateFilter(),
        prefetch: true,
        pageSize: 10,
        pageNum: 1
      };
    },
    columns() {
      return this.pageData?.content?.columns;
    },
    showCountDown() {
      return false;
    }
  },
  async created() {
    const data = this.generateFilter();
    await Promise.all([this.getPlp(data), this.getActivityInfo()]);
    this.render = true;
  },
  methods: {
    // WCM配置的过滤数据
    generateFilter() {
      return {
        activityCode: this.pageData?.content?.activityCode || '',
        activityType: this.pageData?.content?.activityType || ''
      };
    },
    async getPlp(data) {
      this.rerender = false;
      const Plp = ['home', 'LandingPage', 'LandingPage-all'].includes(this.$route.name)
        ? CatalogWcmProduct()
        : CatalogProduct();
      await Plp.init({ ...data });
      this.plp = Plp;
    },
    async getActivityInfo() {
      const { activityCode, activityType } = this.pageData?.content || {};
      const activityInfo = {
        activityCode,
        activityType
      };
      const data = await useLandingPage().getActivityDetail({
        ...activityInfo
      });
      this.activityInfo = data;
    }
  }
};
</script>
