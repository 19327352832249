<template>
  <ul
    ref="reference"
    class="lp-product-list"
    :class="`lp-product-list-column-${columns}`"
    @touchmove="onTouchmove"
  >
    <li v-for="(item, index) in dataList" :key="index">
      <!-- <nuxt-link
        :to="{ name: 'pdp', params: { code: item.productNo } }"
        target="_blank"
        :module_name="item.operationData.module_name"
        :module_rank="item.operationData.module_rank"
        :operation_name="item.operationData.operation_name"
        :operation_id="item.operationData.operation_id"
        :operation_rank="item.operationData.operation_rank"
        :destination_url="item.operationData.destination_url"
        click_type="商品"
        :expose_type="'手动滑动'"
        :product_rank="item.operationData.product_rank"
        :primary_category="item.operationData.primary_category"
        :secondary_category="item.operationData.secondary_category"
        :third_category="item.operationData.third_category"
        :activity_attribute="item.operationData.activity_attribute"
        :gender="item.operationData.gender"
        :spu_id="item.operationData.spu_id"
        :spu_name="item.operationData.spu_name"
        :sales_price="item.operationData.sales_price"
        :list_price="item.operationData.list_price"
        class="operation-img-link"
      > -->
      <Item :data="item" />
      <!-- </nuxt-link> -->
    </li>
  </ul>
</template>

<script>
import Item from '@/components/PLP/lpItem.vue';
export default {
  components: { Item },
  props: {
    dataList: {},
    columns: {},
    isSlide: Boolean
  },
  data() {
    return {
      scrollLeft: 0,
      lock: true,
      time: null
    };
  },
  methods: {
    onTouchmove(e) {
      if (!this.isSlide) return;
      // this.scrollLeft = this.$refs.reference.scrollLeft;
      // const scrollDic = this.$refs.reference.scrollWidth - this.$refs.reference.offsetWidth;
      e.stopPropagation();
    },
    onTouchend() {
      this.lock = true;
    },
    onScroll() {
      this.timer = setTimeout(() => {
        this.lock = false;
      }, 100);
    }
  }
};
</script>

<style lang="scss">
.lp-product-list {
  li {
    max-width: 100% !important;
    min-width: 100% !important;
    overflow: hidden;
  }
}
// grid-template-columns: repeat(auto-fill, minmax(calc((100% - 96px) / 3), 1fr)) !important;
//grid-gap: 48px !important;
@media (max-width: 1440px) {
  .lp-product-list-column-2 {
    grid-gap: 24px !important;
  }
}
.lp-product-list-column-2,
.lp-product-list-column-3,
.lp-product-list-column-4,
.lp-product-list-column-5 {
  display: grid;
  grid-gap: 24px 48px;
  grid-template-columns: repeat(auto-fill, auto, 1fr) !important;
}
.lp-product-list-column-2 {
  grid-template-columns: repeat(2, 1fr);
}
.lp-product-list-column-3 {
  grid-template-columns: repeat(3, 1fr);
}
.lp-product-list-column-4 {
  grid-template-columns: repeat(4, 1fr);
}
.lp-product-list-column-5 {
  grid-template-columns: repeat(5, 1fr);
}

.page-layout_mobile {
  .lp-product-list-column-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 6px), 1fr)) !important;
    grid-gap: 12px !important;
  }
  .lp-product-list-column-3 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(33.3% - 8px), 1fr)) !important;
    grid-gap: 12px !important;
  }
  .lp-product-list-column-4 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - 9px), 1fr)) !important;
    grid-gap: 12px !important;
  }
  .lp-product-list-column-5 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - 9.6px), 1fr)) !important;
    grid-gap: 12px !important;
  }
}
.widget-widget-product-slide,
.widget-widget-product-slide-dark {
  overflow: visible;

  .lp-product-list {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex !important;
    // padding-left: 20px;
    li {
      width: 100px !important;
      max-width: 100px !important;
      min-width: 100px !important;
    }
  }
}
.page-layout_mobile
  .widget-widget-product-slide-dark
  .page-plp_product__lp-item
  .sp-plp-card__name {
  .product-name {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.page-layout_mobile .widget-widget-product-slide-dark {
  .page-plp_product__lp-item {
    .sp-plp-card__price,
    .sp-lp-card__final-price {
      padding-left: 8px;
      padding-right: 8px;
      padding-bottom: 8px;
      height: auto;
    }
  }
}
</style>
