<template>
  <Video
    v-if="type === 'ccvideo'"
    style="width: 100%; height: auto; margin: 0px auto"
    :video="data.content.id"
    :auto-play="true"
    :loop="true"
    :capture-img="data.content.imageUrl"
  />
</template>

<script>
import Video from './../Video/VideoPlayerE.vue';
export default {
  components: {
    Video
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    type() {
      return this.data.type;
    }
  }
};
</script>
